<template>
  <section id="iq-favorites">
    <b-container>
      <div class="mb-4">
        <b-row>
          <b-col sm="12" class="overflow-hidden">
            <div
              class="iq-main-header d-flex align-items-center justify-content-between"
            >
              <h6 class="main-title">Siguiente Video</h6>
            </div>
          </b-col>
        </b-row>
        <DetailVideoThumnbnail :episodeData="episode" />
      </div>
    </b-container>
  </section>
</template>
<script>
import DetailVideoThumnbnail from "./Components/DetailVideoThumbnail.vue"

export default {
  name: "NextEpisode",
  components: { DetailVideoThumnbnail },
  props: {
    episode: Object
  }
}
</script>
<style>
.thumb-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media (min-width: 576px) {
  .thumb-desc {
    display: -webkit-box;
  }
}

@media (min-width: 992px) {
  .thumb-desc {
    display: none;
  }
}

@media (min-width: 1600px) {
  .thumb-desc {
    display: -webkit-box;
  }
}
</style>
