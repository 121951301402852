<template>
  <router-link
    class="d-block"
    :to="{
      name: 'landing-page.category-detail',
      query: { movieId: episodeData.id },
    }"
  >
    <div class="row">
      <b-col cols="12" sm="6" md="6" lg="12" xl="6">
        <div class="epi-box">
          <div class="epi-img">
            <img :data-src="episodeData.image" class="img-fluid img-zoom lazyload" alt="" style="width: 100%; height: auto;" />
            <div class="episode-play-info">
              <div class="episode-play">
                <i class="ri-play-fill"></i>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="12" xl="6">
        <div>
          <h5 class="episode-number">{{ episodeData.title }}</h5>
          <p class="text-white m-0 mt-3">Publicación: {{ episodeData.date }}</p>
          <p class="text-white m-0">Duración: {{ episodeData.time }}</p>
          <p class="text-white mb-0 thumb-desc">
            {{ episodeData.description }}
          </p>
        </div>
      </b-col>
    </div>
  </router-link>
</template>
<script>
import dateUtilities from "../../../../Utils/date-utilities.js"

export default {
  name: "NextEpisode",
  props: {
    episodeData: Object
  },
  components: {},
  mounted() {
    this.$set(
      this.episodeData,
      "date",
      dateUtilities.firebaseDateParse(this.episodeData.fecha)
    )
  }
}
</script>
<style>
.epi-box {
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 50%);
  position: relative;
  width: 100%;
}

.epi-img {
  overflow: hidden;
}

.img-zoom {
  width: 100%;
}

.thumb-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media (min-width: 576px) {
  .thumb-desc {
    display: -webkit-box;
  }
}

@media (min-width: 992px) {
  .thumb-desc {
    display: none;
  }
}

@media (min-width: 1600px) {
  .thumb-desc {
    display: -webkit-box;
  }
}
</style>
