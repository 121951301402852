<template>
  <ul class="list-inline share-icons music-play-lists">
    <li class="share">
      <span @click="share"><i class="ri-share-fill"></i></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Shared",
  props: {
    videoTitle: String
  },
  methods: {
    async share() {
      try {
        if (navigator.share) {
          await navigator.share({
            title: this.videoTitle,
            url: this.getVideoLink()
          })
        } else {
          this.showFallbackShareOptions()
        }
      } catch (error) {
        console.error("Error al compartir:", error)
      }
    },
    getVideoLink() {
      // Replace this with your actual logic to get the video link
      return window.location.href
    },
    showFallbackShareOptions() {
      // Provide your fallback logic here, for example, showing other share buttons
      console.log("Web Share API is not supported in this browser.")
    }
  }
}
</script>

<style>
</style>
