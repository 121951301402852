/**
 * Component: RegisteredQualified
 * Description: This component displays the details of a registered and qualified career.
 * Props:
 *   - videoId: String - The ID of the video associated with the career.
 * Data:
 *   - registration_carrer: Array - The array containing the registration career details.
 *   - keys: Object - The object containing the keys and their corresponding labels for the career details.
 * Methods:
 *   - getRegistrationCarrer: Fetches the registration career details from the database.
 * Lifecycle Hooks:
 *   - created: Calls the getRegistrationCarrer method when the component is created.
 */
<template>
  <div class="registered-qualified">
    <div v-for="(item, index) in registration_carrer" :key="index">
      <template v-if="item">
        <b>{{ keys[index] }}</b
        >: {{ item }}
      </template>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app"
import "firebase/firestore"

const db = firebase.firestore()

export default {
  name: "RegisteredQualified",
  props: {
    videoId: String
  },
  data() {
    return {
      registration_carrer: [],
      keys: {
        registration_q: "Registro calificado",
        code_snies: "Código Snies",
        title: "Titulo",
        duration: "Duración",
        methodology: "Metodologia",
        location: "Ubicación",
        credits: "Creditos",
        national_acreditation: "Acreditación nacional",
        international_acreditation: "Acreditación internacional",
        validity: "Vigencia"
      }
    }
  },
  async created() {
    await this.getRegistrationCarrer()
  },
  methods: {
    async getRegistrationCarrer() {
      const querySnapshot = await this.$store.dispatch(
        "Category/getRegistrationVideoById",
        {
          db,
          firebase,
          videoId: this.videoId
        }
      )
      querySnapshot.forEach((doc) => {
        this.registration_carrer = doc.data()
      })
    }
  }
}
</script>

<style scoped>
.registered-qualified {
  font-size: 0.9rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
