<template>
  <div>
    <div class="d-flex align-items-center text-white text-detail mt-3 mb-0">
      Créditos <i class="ri-copyright-line"></i>
      <button class="round-button" @click="toggleMostrarCreditos">
        {{ showCredits ? "-" : "+" }}
      </button>
    </div>
    <div v-if="showCredits">
      <p>Estudiantes:</p>
      <ul>
        <li
          v-for="(estudiante, index) in this.creditos.estudiantes"
          :key="index"
        >
          {{ estudiante }}
        </li>
      </ul>
      <p>Profesores:</p>
      <ul>
        <li
          v-for="(profesor, index) in this.creditos.profesores"
          :key="index"
        >
          {{ profesor }}
        </li>
      </ul>
      <p>Extras funcionarios:</p>
      <ul>
        <li
          v-for="(extra, index) in this.creditos.extras"
          :key="index"
        >
          {{ extra }}
        </li>
      </ul>
      <p>Agradecimientos:</p>
      <ul>
        <li
          v-for="(agradecimiento, index) in this.creditos.agradecimientos"
          :key="index"
        >
          {{ agradecimiento }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app"
import "firebase/firestore"

const db = firebase.firestore()

export default {
  name: "Credits",
  props: {
    videoId: String,
    mostrarCreditos: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      creditos: [],
      isMostrarCreditos: this.mostrarCreditos
    }
  },
  async created() {
    await this.getCreditosVideoById()
  },
  methods: {
    toggleMostrarCreditos() {
      this.isMostrarCreditos = !this.isMostrarCreditos
    },

    async getCreditosVideoById() {
      const querySnapshot = await this.$store.dispatch(
        "Category/getCreditosVideoById",
        {
          db,
          firebase,
          videoId: this.videoId
        }
      )
      querySnapshot.forEach((doc) => {
        this.creditos = doc.data()
      })
    }
  },
  computed: {
    showCredits() {
      return this.isMostrarCreditos
    }
  }
}
</script>

<style scoped></style>
