<template>
  <section id="iq-favorites">
    <b-container>
      <div class="mb-4">
        <b-row class="">
          <b-col sm="12" class="overflow-hidden">
            <div class="
                iq-main-header
                d-flex
                align-items-center
                justify-content-between
              ">
              <h6 class="main-title">Videos Relacionados</h6>
            </div>
          </b-col>
        </b-row>
        <div class="row">
          <b-col>
            <DetailVideoThumnbnail md="12" class="mb-4" v-for="(item, index) in videosToShow" :key="index"
              :episodeData="episodesData[index]">
            </DetailVideoThumnbnail>
          </b-col>
        </div>
      </div>
      <b-row class="justify-content-center">
        <button class="show-more-videos" v-on:click="showMoreVideos" v-if="episodesToShow < episodesData.length">
          <span class="mr-2">Cargar más</span>
          <i class="fa fa-angle-down"></i>
        </button>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import DetailVideoThumnbnail from "./Components/DetailVideoThumbnail.vue"

export default {
  name: "LatestEpisode",
  components: { DetailVideoThumnbnail },
  props: {
    episodesData: Array
  },
  data() {
    return {
      episodesToShow: 5
    }
  },
  computed: {
    videosToShow() {
      return this.episodesData.filter(
        (item, index) => index < this.episodesToShow
      )
    }
  },
  methods: {
    showMoreVideos() {
      if (this.episodesToShow < this.episodesData.length) {
        this.episodesToShow += 5
      }
    }
  }
}
</script>

<style>
.show-more-videos {
  padding: 5px 15px;
  height: 50px;
  color: var(--iq-white);
  background: var(--iq-primary);
  font-size: 1.2rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show-more-videos span {
  font-size: 1.2rem;
}
</style>
