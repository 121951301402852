<template>
  <div>
    <b-embed type="video" @play="changeVideoStartTime" ref="banner-main-video" aspect="16by9" controls>
      <source :src="videoUrl + `#t=${videoTimeStamp}`" type="video/mp4" />
    </b-embed>
  </div>
</template>

<script>
export default {
  name: "BannerVideo",
  props: ["videoUrl"],
  data() {
    return {
      videoStarted: false,
      videoTimeStamp: 0
    }
  },
  methods: {
    changeVideoStartTime() {
      if (!this.videoStarted) {
        this.$refs["banner-main-video"].currentTime = 0
        this.videoStarted = true
        // Visualizations
      }
    }
  }
}
</script>
