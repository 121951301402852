<template>
  <div
    class="show-detail-grid flex-column flex-lg-row"
    v-if="seriesEpisodes.length > 0"
  >
    <div class="video-info">
      <div class="banner-video-container" v-if="currentEpisodeInfo.videoUrl">
        <BannerVideo :videoUrl="currentEpisodeInfo.videoUrl" />
      </div>
      <div class="video-detail-container">
        <Detail :episodeInfo="currentEpisodeInfo" :seriesData="seriesData" />
      </div>
    </div>
    <div class="latest-episodes-container pt-3">
      <NextEpisode :episode="getNextEpisode" />
      <LatestEpisode :episodesData="seriesEpisodes" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app"
import "firebase/firestore"
import Detail from "./ShowDetailPage/Detail"
import LatestEpisode from "./ShowDetailPage/LatestEpisode"
import NextEpisode from "./ShowDetailPage/NextEpisode.vue"
import BannerVideo from "./ShowDetailPage/BannerVideo"

const db = firebase.firestore()

export default {
  name: "ShowDetail",
  props: {
    componentReference: String
  },
  data() {
    return {
      currentEpisodeInfo: {},
      seriesData: [
        {
          title: "UNINORTE: LA MEJOR ETAPA DE TU VIDA"
        },
        { title: "CONOCE NUESTROS PROGRAMAS ACADÉMICOS" }
      ],
      seriesEpisodes: []
    }
  },
  components: {
    BannerVideo,
    Detail,
    NextEpisode,
    LatestEpisode
  },
  computed: {
    getNextEpisode() {
      const videoList = this.seriesEpisodes
      const currentVideo = videoList.findIndex((elemento) => {
        return this.currentEpisodeInfo.id === elemento.id
      })
      const nextEpisodeIndex = currentVideo + 1
      if (nextEpisodeIndex < 1 || nextEpisodeIndex >= videoList.length) {
        return videoList[0]
      }
      return videoList[nextEpisodeIndex]
    }
  },
  mounted() {
    this.mainVideoOnLoad()
  },
  watch: {
    $route(newRoute) {
      this.mainVideoOnLoad(newRoute)
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  },
  methods: {
    async mainVideoOnLoad(route = this.$route) {
      const episodeInfo = await this.getCurrentEpisode(route)
      this.getSeriesVideos(episodeInfo)
    },
    getCurrentEpisode(route) {
      const movieId = route.query.movieId.trim()
      const documentoRef = firebase
        .firestore()
        .doc(`videos/${movieId}/vistas/${movieId}`)
      // Actualizar el campo "visualizations" sumando 1 a su valor actual
      documentoRef
        .update({
          visualizations: firebase.firestore.FieldValue.increment(1)
        })
        .catch((error) => {
          console.error(
            "Error al actualizar el campo 'visualizations':",
            error
          )
        })

      return this.$store
        .dispatch("Category/getVideo", {
          db,
          firebase,
          id: movieId
        })
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.currentEpisodeInfo = doc.data()
          })

          return this.currentEpisodeInfo
        })
    },
    getSeriesVideos(episodeInfo) {
      this.seriesEpisodes = []
      this.$store
        .dispatch("Category/getVideosBySeries", {
          db,
          firebase,
          id: episodeInfo.idSerie
        })
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const video = doc.data()
            this.seriesEpisodes.push(video)
          })
        })
    }
  }
}
</script>

<style>
.video-info {
  display: flex;
  flex-direction: column;
  min-width: 70%;
}

.show-detail-grid {
  display: flex;
}
</style>
