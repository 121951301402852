<template>
  <section class="movie-detail container">
    <b-row>
      <b-col lg="8" md="12">
        <div class="trending-info season-info g-border">
          <div class="big-title text-uppercase mt-2">
            {{ seriesData[episodeInfo.idSerie].title }}
          </div>
          <b-row align-h="between" class="mt-2">
            <b-col sm="10" md="10" lg="10" xl="10" cols="12" class="mb-2 pt-2">
              <div
                class="d-flex align-items-center text-white text-detail episode-name mb-0"
              >
                <span>{{ episodeInfo.title }}</span>
              </div></b-col
            >
            <b-col sm="2" md="2" lg="2" xl="2" cols="12">
              <Shared :videoTitle="episodeInfo.title"
            /></b-col>
          </b-row>

          <p class="trending-dec w-100 mb-0">{{ episodeInfo.description }}</p>

          <RegisteredQualified
            v-if="episodeInfo.idSerie === '1'"
            :videoId="episodeInfo.id"
          />
          <Credits
            v-if="episodeInfo.idSerie === '0'"
            :mostrarCreditos="mostrarCreditos"
            :videoId="episodeInfo.id"
          />
        </div>
      </b-col>
      <b-col lg="4" md="12">
        <InterestLinks :videoId="episodeInfo.id" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import RegisteredQualified from "./Components/RegisteredQualified.vue"
import InterestLinks from "./Components/InterestLinks.vue"
import Credits from "./Components/Credits.vue"
import Shared from "./Components/Shared.vue"

export default {
  name: "Detail",
  components: { RegisteredQualified, InterestLinks, Credits, Shared },
  props: {
    episodeInfo: Object,
    seriesData: Array
  },
  data() {
    return {
      mostrarCreditos: false
    }
  }
}
</script>

<style>
.interest-links a {
  color: #e93578;
}

.interest-links ul {
  list-style: none;
}

.interest-links li::before {
  content: "\2022";
  color: #e93578;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.interest-links li:hover {
  text-decoration: underline;
}

.round-button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--iq-primary);
  color: white;
  font-size: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.registro-calificado {
  padding-top: 1rem;
}
</style>
