<template>
  <div class="interest-links p-2 p-xl-4">
    <h5>Enlaces de interés</h5>
    <ul>
      <li v-for="(item, index) in currentEpisodeLinks" :key="index">
        <a v-bind:href="item.link" target="_blank">{{ item.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "firebase/app"
import "firebase/firestore"

const db = firebase.firestore()

export default {
  name: "InterestLinks",
  props: {
    videoId: String
  },
  data() {
    return {
      currentEpisodeLinks: []
    }
  },
  created() {
    this.getEnlacesVideoById()
  },
  methods: {
    async getEnlacesVideoById() {
      const querySnapshot = await this.$store.dispatch(
        "Category/getEnlacesVideoById",
        {
          db,
          firebase,
          videoId: this.videoId
        }
      )
      querySnapshot.forEach((doc) => {
        const response = doc.data().enlaces
        const data = []

        response.forEach((element) => {
          if (element instanceof firebase.firestore.DocumentReference) {
            // Si el elemento es una referencia a otro documento
            element
              .get()
              .then((documentoReferenciado) => {
                if (documentoReferenciado.exists) {
                  console.log(
                    "Datos del documento referenciado:",
                    documentoReferenciado.data().enlaces
                  )
                  const enlace = documentoReferenciado.data().enlaces
                  enlace.forEach((element) => {
                    data.push(element)
                  })
                } else {
                  console.log("El documento referenciado no existe.")
                }
              })
              .catch((error) => {
                console.error(
                  "Error al obtener el documento referenciado:",
                  error
                )
              })
          } else {
            data.push(element)
          }
        })
        this.currentEpisodeLinks = data
      })
    }
  }
}
</script>

<style>
/* Add any necessary styles here */
</style>
